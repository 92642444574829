<template lang="html">
  <div class="">
    <h1>Administracija Testova</h1>
  </div>
  <div class="overlay">
    <TabNav
      :tabs="companyList"
      :selected="selected"
      :tabType="true"
      @selected="setSelected"
      id="companyNav"
    >
      <Tab
        v-for="(company, i) in companyList"
        :key="company"
        :isSelected="selected === company"
      >
        <div v-if="loading" class="loader"></div>

        <div v-if="!loading">
          <div class="validation">
            {{ validation }}
          </div>

          <button
            class="dd"
            type="button"
            name="button"
            @click="administerProcenaEdukacijeHere()"
          >
            Dodaj novi upitnik za procenu edukacije
          </button>
          <button
            class="dd"
            type="button"
            name="button"
            @click="administerPerformanceEval(listOfListsOfUsers[i], company)"
          >
            <b>!!!</b>Dodaj Novi Performance Evaluation<b>!!!</b>
          </button>
          <button
            class="dd"
            type="button"
            name="button"
            @click="
              administerPerformanceEvalLicni(listOfListsOfUsers[i], company)
            "
          >
            <b>!!!</b>Dodaj Novi Performance Evaluation sa Istim licnim KPI<b
              >!!!</b
            >
          </button>
          <p>
            <b>!PAŽNJA NEPOVRATNA OPCIJA!</b> Kada se aktivira arhiviraju se
            odrađeni KPI i dodeljuju se novi. Kompanijski i kompetence će
            ostati, ali svi lični KPI moraju opet da se unose!
          </p>

          <div class="w-100 px-3">
            <div class="border border-dark w-100 rounded-2 p-2 d-flex">
              <span v-if="itemsFromServer.length == 0"
                >Ova kompanija nema sačuvane upitnike.
              </span>
              <label
                v-if="itemsFromServer.length > 0"
                class="fw-bold p-0 m-0 text-nowrap"
                >Postojeći upitnici:
                <select
                  @change="getSelectedItemFromServer(selectedQuestionnaireName)"
                  name="type"
                  id="type"
                  v-model="selectedQuestionnaireName"
                >
                  <option
                    :class="item.active ? 'cc' : 'bb'"
                    class="m-3"
                    v-for="(item, i) in itemsFromServer"
                    :key="i"
                    >{{ item.name }}</option
                  >
                </select>
              </label>
              <div
                v-if="selectedQuestionnaireName && itemsFromServer.length > 0"
                :class="
                  selectedQuestionnaire.active
                    ? 'cc border-success'
                    : 'bb border-danger'
                "
                class="ms-5 px-3 border border-2  rounded-2 d-flex align-items-center"
              >
                <span class="fw-bold">{{ selectedQuestionnaireName }} </span
                ><span v-if="!selectedQuestionnaire.active" class="px-1"
                  >nije</span
                >
                <span v-if="selectedQuestionnaire.active" class="px-1"
                  >jeste</span
                >
                aktivan.
              </div>
              <button
                @click="activateQuestionnaire()"
                v-if="
                  selectedQuestionnaireName &&
                    !selectedQuestionnaire.active &&
                    itemsFromServer.length > 0
                "
                class="btn btn-success btn-sm ms-1"
              >
                Aktiviraj
              </button>
              <button
                @click="deactivateQuestionnaire()"
                v-if="
                  selectedQuestionnaire.active && itemsFromServer.length > 0
                "
                class="btn btn-danger btn-sm ms-1"
              >
                Ugasi
              </button>
              <span
                v-if="itemsFromServer.length > 0 && activeError"
                class="text-danger ms-3 fw-bold"
                >Već postoji aktivan upitnik.</span
              >
            </div>
          </div>

          <!--  <div class="w-100 px-3">
            <div class="border border-dark w-100 rounded-2 p-2 d-flex">
              <span v-if="procenaFromServer.length == 0">Ova kompanija nema sačuvane Procene. </span>
              <label v-if="procenaFromServer.length > 0" class="fw-bold p-0 m-0 text-nowrap">Postojeće procene:
                <select @change="getSelectedProcenaFromServer(selectedProcenaName)" name="type" id="type"
                  v-model="selectedProcenaName">
                  <option :class="item.active ? 'cc' : 'bb'" class="m-3" v-for="(item, i) in procenaFromServer" :key="i">
                    {{
                      item.name }}</option>
                </select>
              </label>
              <div v-if="selectedProcenaName && procenaFromServer.length > 0"
                :class="selectedProcena.active ? 'cc border-success' : 'bb border-danger'"
                class="ms-5 px-3 border border-2  rounded-2 d-flex align-items-center">
                <span class="fw-bold">{{ selectedProcenaName }} </span><span v-if="!selectedProcena.active"
                  class="px-1">nije</span> <span v-if="selectedProcena.active" class="px-1">jeste</span> aktivan.
              </div>
              <button @click="activateProcena()"
                v-if="selectedProcenaName && !selectedProcena.active && procenaFromServer.length > 0"
                class="btn btn-success btn-sm ms-1">Aktiviraj</button>
              <button @click="deactivateProcena()" v-if="selectedProcena.active && procenaFromServer.length > 0"
                class="btn btn-danger btn-sm ms-1">Ugasi</button>
            </div>
          </div> -->

          <div
            class="container"
            v-for="userX in listOfListsOfUsers[i]"
            :key="userX._id"
          >
            <div class="userName">
              {{ userX.name }}
            </div>

            <div class="row w-100 justify-content-center">
              <!-- MODAL -->
              <div
                v-if="userX.modalProcena"
                class="button-container position-fixed w-50 border border-dark border-3 procena-modal text-center p-2"
              >
                <div class="">
                  <span class="fw-bold"
                    >Aktiviraj procenu koju korisnik
                    <span class="fw-bold text-primary">{{ userX.name }}</span>
                    može da vidi</span
                  >
                  <span
                    @click="userX.modalProcena = false"
                    class="text-danger fw-bold border border-danger rounded-3 px-1 float-end pointer close-modal"
                    >X</span
                  >
                </div>
                <div class="py-3">
                  <span
                    :class="
                      userX.availableTest.temporaryProcenaData[i].active
                        ? 'cc'
                        : 'bb'
                    "
                    @click="
                      administerCustomProcenaUserViewThis(
                        userX,
                        item.name,
                        userX.availableTest.temporaryProcenaData[i].active
                      )
                    "
                    v-for="(item, i) in procenaFromServer"
                    :key="i"
                    class="py-1 px-2 border border-dark rounded-3 m-1 pointer"
                    >{{ item.name }}</span
                  >
                </div>
              </div>

              <div class="col-xl-4 d-flex p-0 mx-2">
                <div class="d-flex flex-column">
                  <button
                    class="bb py-0  w-100 h-50"
                    type="button"
                    name="button"
                    @click="administerVQ(userX)"
                    v-if="!userX.availableTest.vqTest"
                  >
                    Vq test
                  </button>
                  <button
                    class="cc py-0  w-100 h-50"
                    type="button"
                    name="button"
                    @click="administerVQDeny(userX)"
                    v-if="userX.availableTest.vqTest"
                  >
                    Vq test
                  </button>

                  <button
                    class="bb small py-0 lh-1 w-100 h-50"
                    type="button"
                    name="button"
                    @click="administerTL(userX)"
                    v-if="!userX.availableTest.testLicnosti"
                  >
                    Test Licnosti
                  </button>
                  <button
                    class="cc small py-0 lh-1 w-100 h-50"
                    type="button"
                    name="button"
                    @click="administerTLDeny(userX)"
                    v-if="userX.availableTest.testLicnosti"
                  >
                    Test Licnosti
                  </button>
                </div>

                <button
                  class="bb small w-25"
                  type="button"
                  name="button"
                  @click="administerCustomQuestionnairePregledThis(userX)"
                  v-if="!userX.availableTest.customQuestionnaireView"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Dozvoli zaposlenom da učestvuje u popunjavanju generisanog upitnika"
                >
                  Generisan Upitnik
                </button>
                <button
                  class="cc small w-25"
                  type="button"
                  name="button"
                  @click="administerCustomQuestionnairePregledDenyThis(userX)"
                  v-if="userX.availableTest.customQuestionnaireView"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Zabrani zaposlenom da učestvuje u popunjavanju generisanog upitnika"
                >
                  Generisan Upitnik
                </button>

                <button
                  class="bb small w-25"
                  type="button"
                  name="button"
                  @click="administerStavovi(userX)"
                  v-if="!userX.availableTest.stavoviZaposlenih"
                >
                  Stavovi Zaposlenih
                </button>
                <button
                  class="cc small w-25"
                  type="button"
                  name="button"
                  @click="administerStavoviDeny(userX)"
                  v-if="userX.availableTest.stavoviZaposlenih"
                >
                  Stavovi Zaposlenih
                </button>

                <div class="d-flex flex-column">
                  <button
                    class="bb w-100 lh-1 h-50"
                    type="button"
                    name="button"
                    @click="administerBelbin(userX)"
                    v-if="!userX.availableTest.belbinTest"
                  >
                    Belbin Test
                  </button>
                  <button
                    class="cc w-100 lh-1 h-50"
                    type="button"
                    name="button"
                    @click="administerBelbinDeny(userX)"
                    v-if="userX.availableTest.belbinTest"
                  >
                    Belbin Test
                  </button>

                  <button
                    class="bb small lh-1 py-0 w-100 h-50"
                    type="button"
                    name="button"
                    @click="administerKpiPregledThis(userX)"
                    v-if="!userX.availableTest.kpiPregled"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Omogući pregled Konačne Procene"
                  >
                    Pregled Kon Proc
                  </button>
                  <button
                    class="cc small lh-1 py-0 w-100 h-50"
                    type="button"
                    name="button"
                    @click="administerKpiPregledDenyThis(userX)"
                    v-if="userX.availableTest.kpiPregled"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Zabrani pregled Konačne Procene"
                  >
                    Pregled Kon Proc
                  </button>
                </div>
              </div>

              <div class="col-xl-8 d-flex p-0 row">
                <div class="col-lg-6 d-flex p-0">
                  <div
                    class="p-0 w-100 border border-dark d-flex justify-content-center button-container"
                  >
                    <div
                      class="row dark d-flex justify-content-center fw-bold py-1"
                    >
                      &nbsp;&nbsp;&nbsp; Feedback 360 &nbsp;&nbsp;&nbsp;
                      <button
                        class="bb col-5 text-nowrap small text-center p-0"
                        type="button"
                        name="button"
                        @click="administerFB(userX)"
                        v-if="!userX.availableTest.feedbackTest"
                      >
                        Upitnik
                      </button>
                      <button
                        class="cc col-5 text-nowrap small text-center p-0"
                        type="button"
                        name="button"
                        @click="administerFBDeny(userX)"
                        v-if="userX.availableTest.feedbackTest"
                      >
                        Upitnik
                      </button>

                      <button
                        class="bb col-5 text-nowrap small text-center p-0"
                        type="button"
                        name="button"
                        @click="administerFeedBackReportthis(userX)"
                        v-if="!userX.availableTest.feedBackReport"
                      >
                        Izveštaj
                      </button>
                      <button
                        class="cc col-5 text-nowrap small text-center p-0"
                        type="button"
                        name="button"
                        @click="administerFeedBackReportDenythis(userX)"
                        v-if="userX.availableTest.feedBackReport"
                      >
                        Izveštaj
                      </button>
                    </div>
                  </div>

                  <div
                    class="p-0 w-100 border border-dark d-flex justify-content-center button-container"
                  >
                    <div
                      class="row dark d-flex justify-content-center fw-bold py-1"
                    >
                      Procena Kandidata

                      <div class="d-flex flex-column col-6 p-0">
                        <button
                          class="bb h-50 text-nowrap small text-center p-0"
                          type="button"
                          name="button"
                          @click="administerProcenaPregledThis(userX)"
                          v-if="!userX.availableTest.procenaPregled"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Omogući pregled stare procene koja nije generisana."
                        >
                          Pregled
                        </button>
                        <button
                          class="cc h-50 text-nowrap small text-center p-0"
                          type="button"
                          name="button"
                          @click="administerProcenaPregledDenyThis(userX)"
                          v-if="userX.availableTest.procenaPregled"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Zabrani pregled stare procene koja nije generisana."
                        >
                          Pregled
                        </button>

                        <button
                          :class="
                            userX.availableTest.customProcenaView.length > 0
                              ? 'cc'
                              : 'bb'
                          "
                          class="h-50 text-nowrap small text-center p-0"
                          type="button"
                          name="button"
                          @click="userX.modalProcena = !userX.modalProcena"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Otvori modal u kome možeš da dozvoliš pregled generisanih procena."
                        >
                          Pregled Izbor
                        </button>
                      </div>

                      <button
                        class="bb col-4 text-nowrap small text-center p-0"
                        type="button"
                        name="button"
                        @click="administerProcenaIzmenaThis(userX)"
                        v-if="!userX.availableTest.procenaIzmena"
                      >
                        Izmena
                      </button>
                      <button
                        class="cc col-4 text-nowrap small text-center p-0"
                        type="button"
                        name="button"
                        @click="administerProcenaIzmenaDenyThis(userX)"
                        v-if="userX.availableTest.procenaIzmena"
                      >
                        Izmena
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 d-flex p-0">
                  <div
                    class="p-0 w-100 border border-dark d-flex justify-content-center button-container"
                  >
                    <div
                      class="row dark d-flex justify-content-center fw-bold py-1"
                    >
                      Plan Licnog Razvoja

                      <div class="d-flex flex-column col-6 p-0">
                        <button
                          class="bb h-50 p-0 small text-nowrap text-center"
                          type="button"
                          name="button"
                          @click="administerPDPPregledThis(userX)"
                          v-if="!userX.availableTest.pdpView"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pregled samo sopstvenog Plana ličnog razvoja."
                        >
                          Pregled
                        </button>
                        <button
                          class="cc h-50 p-0 small text-nowrap text-center"
                          type="button"
                          name="button"
                          @click="administerPDPPregledDenyThis(userX)"
                          v-if="userX.availableTest.pdpView"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pregled samo sopstvenog Plana ličnog razvoja."
                        >
                          Pregled
                        </button>

                        <button
                          class="bb h-50 text-nowrap small text-center p-0"
                          type="button"
                          name="button"
                          @click="administerPDPPregledAllThis(userX)"
                          v-if="!userX.availableTest.pdpViewAll"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pregled Planova ličnog razvoja svih zaposlenih u kompaniji."
                        >
                          Pregled Svi
                        </button>
                        <button
                          class="cc h-50 text-nowrap small text-center p-0"
                          type="button"
                          name="button"
                          @click="administerPDPPregledAllDenyThis(userX)"
                          v-if="userX.availableTest.pdpViewAll"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pregled Planova ličnog razvoja svih zaposlenih u kompaniji."
                        >
                          Pregled Svi
                        </button>
                      </div>

                      <button
                        class="bb col-4 text-center small p-0"
                        type="button"
                        name="button"
                        @click="administerPDPIzmenaThis(userX)"
                        v-if="!userX.availableTest.pdpIzmena"
                      >
                        Izmena
                      </button>
                      <button
                        class="cc col-4 text-center small p-0"
                        type="button"
                        name="button"
                        @click="administerPDPIzmenaDenyThis(userX)"
                        v-if="userX.availableTest.pdpIzmena"
                      >
                        Izmena
                      </button>
                    </div>
                  </div>

                  <button
                    class="bb small"
                    type="button"
                    name="button"
                    @click="administerKpiHistoryPregledThis(userX)"
                    v-if="!userX.availableTest.kpiHistoryPregled"
                  >
                    Kpi Istorija Pregled
                  </button>
                  <button
                    class="cc small"
                    type="button"
                    name="button"
                    @click="administerKpiHistoryPregledDenyThis(userX)"
                    v-if="userX.availableTest.kpiHistoryPregled"
                  >
                    Kpi Istorija Pregled
                  </button>

                  <button
                    class="cc"
                    type="button"
                    name="button"
                    @click="resetKpithis(userX)"
                  >
                    RESTART KPI
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tab>
    </TabNav>
  </div>
</template>

<script>
// @ is an alias to /src
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from "vuex";
// eslint-disable-next-line no-unused-vars
import TabNav from "@/components/TabNav.vue";
import Tab from "@/components/Tab.vue";

export default {
  computed: mapGetters(["companys", "users", "user", "usersSameCompany"]),
  components: {
    TabNav,
    Tab,
  },
  data() {
    return {
      selected: "",
      companyList: [],
      //each element is a list of users (different lists are for different companies)
      listOfListsOfUsers: [],
      validation: "",
      currentCompanyName: "",
      iMemory: 0,
      loading: true,
      itemsFromServer: [],
      selectedQuestionnaire: {},
      selectedQuestionnaireName: null,
      selectedQuestionnaireIndex: null,
      activeError: null,
      procenaFromServer: [],
      selectedProcena: {},
      selectedProcenaName: null,
      selectedProcenaIndex: null,
    };
  },
  methods: {
    ...mapActions([
      "administerCustomProcenaUserView",
      "administerCustomProcenaUserViewDeny",
      "editActiveCompanyCustomQuestionnaire",
      "editActiveCompanyCustomProcena",
      "administerPDPIzmenaDeny",
      "administerPDPIzmena",
      "administerPDPPregled",
      "administerPDPPregledDeny",
      "administerPDPPregledAll",
      "administerPDPPregledAllDeny",
      "resetKpi",
      "administerKpiHistoryPregledDeny",
      "administerKpiHistoryPregled",
      "setLicniKpi",
      "getCollegues",
      "administerProcenaEdukacije",
      "administerEducationEval",
      "administerProcenaPregled",
      "administerProcenaPregledDeny",
      "administerFeedBackReport",
      "administerFeedBackReportDeny",
      "addPerformanceEval",
      "addPerformanceEvalLicni",
      "submitServiceAplication",
      "getCompanies",
      "getAllUsers",
      "getProfile",
      "administerVQTest",
      "administerTLTest",
      "administerStavoviTest",
      "administerFBTest",
      "administerBelbinTest",
      "administerVQTestDeny",
      "administerTLTestDeny",
      "administerStavoviTestDeny",
      "administerFBTestDeny",
      "administerBelbinTestDeny",
      "administerKpiPregled",
      "administerKpiPregledDeny",
      "administerCustomQuestionnairePregled",
      "administerCustomQuestionnairePregledDeny",
      "administerProcenaIzmena",
      "administerProcenaIzmenaDeny",
    ]),

    async getSavedQuestionnaires() {
      this.companys.forEach((company) => {
        if (company.name == this.selected) {
          this.itemsFromServer = company.customQuestionnaire;
        }
      });
    },
    async getSavedProcena() {
      this.companys.forEach((company) => {
        if (company.name == this.selected) {
          this.procenaFromServer = company.customProcena;
        }
      });
    },
    async getSelectedItemFromServer(name) {
      for (let i = 0; i < this.itemsFromServer.length; i++) {
        if (this.itemsFromServer[i].name == name) {
          this.selectedQuestionnaire = this.itemsFromServer[i];
          this.selectedQuestionnaireIndex = i;
        }
      }
    },
    async getSelectedProcenaFromServer(name) {
      for (let i = 0; i < this.procenaFromServer.length; i++) {
        if (this.procenaFromServer[i].name == name) {
          this.selectedProcena = this.procenaFromServer[i];
          this.selectedProcenaIndex = i;
        }
      }
    },
    async activateQuestionnaire() {
      for (let i = 0; i < this.itemsFromServer.length; i++) {
        if (this.itemsFromServer[i].active == true) {
          console.log(this.itemsFromServer[i].active);
          this.activeError = true;
          break;
        } else {
          this.activeError = false;
          console.log(this.activeError);
        }
      }

      if (!this.activeError) {
        let questionnaireData = {
          value: true,
          index: this.selectedQuestionnaireIndex,
          companyName: this.selected,
        };
        await this.editActiveCompanyCustomQuestionnaire(questionnaireData);
        await this.getCompanies();
        await this.getSavedQuestionnaires();
        await this.getSelectedItemFromServer(this.selectedQuestionnaireName);
      }
    },
    async activateProcena() {
      let procenaData = {
        value: true,
        index: this.selectedProcenaIndex,
        companyName: this.selected,
      };
      await this.editActiveCompanyCustomProcena(procenaData);
      await this.getCompanies();
      await this.getSavedProcena();
      await this.getSelectedProcenaFromServer(this.selectedProcenaName);
    },
    async deactivateQuestionnaire() {
      this.activeError = false;
      let questionnaireData = {
        value: false,
        index: this.selectedQuestionnaireIndex,
        companyName: this.selected,
      };
      await this.editActiveCompanyCustomQuestionnaire(questionnaireData);
      await this.getCompanies();
      await this.getSavedQuestionnaires();
      await this.getSelectedItemFromServer(this.selectedQuestionnaireName);
    },
    async deactivateProcena() {
      let procenaData = {
        value: false,
        index: this.selectedProcenaIndex,
        companyName: this.selected,
      };
      await this.editActiveCompanyCustomProcena(procenaData);
      await this.getCompanies();
      await this.getSavedProcena();
      await this.getSelectedProcenaFromServer(this.selectedProcenaName);
    },
    async setSelected(tab) {
      this.loading = true;
      this.selected = tab;
      this.getSavedQuestionnaires();
      this.selectedQuestionnaire = {};
      this.selectedQuestionnaireName = null;
      this.selectedQuestionnaireIndex = null;
      this.activeError = false;
      this.getSavedProcena();
      this.selectedProcena = {};
      this.selectedProcenaName = null;
      this.selectedProcenaIndex = null;
      this.activeProcenaError = false;

      for (var i = 0; i < this.companyList.length; i++) {
        if (this.companyList[i] == this.selected) {
          this.currentCompanyName = this.companyList[i];
          this.iMemory = i;
        }
      }

      await this.getCollegues(this.currentCompanyName);
      this.listOfListsOfUsers[this.iMemory] = [];

      for (i = 0; i < this.usersSameCompany.length; i++) {
        this.listOfListsOfUsers[this.iMemory].push(this.usersSameCompany[i]);
      }
      this.temporaryDataForProcenaModal();
      this.loading = false;
    },

    async administerKpiHistoryPregledThis(userAdminister) {
      await this.administerKpiHistoryPregled(userAdminister._id);
      this.validation = `Omogucen pregled Kpi istorije za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerKpiHistoryPregledDenyThis(userAdminister) {
      await this.administerKpiHistoryPregledDeny(userAdminister._id);
      this.validation = `Uklonjen pregled Kpi istorije za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },

    async administerPDPPregledThis(userAdminister) {
      await this.administerPDPPregled(userAdminister._id);
      this.validation = `Omogućen Pregled Procena za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerPDPPregledDenyThis(userAdminister) {
      await this.administerPDPPregledDeny(userAdminister._id);
      this.validation = `Uklonjen Pregled Procena za  ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerPDPPregledAllThis(userAdminister) {
      await this.administerPDPPregledAll(userAdminister._id);
      this.validation = `Omogućen Pregled Procena svih zaposlenih za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerPDPPregledAllDenyThis(userAdminister) {
      await this.administerPDPPregledAllDeny(userAdminister._id);
      this.validation = `Uklonjen Pregled Procena svih zaposlenih za  ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerPDPIzmenaThis(userAdminister) {
      await this.administerPDPIzmena(userAdminister._id);
      this.validation = `Omogućen Pregled Procena za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerPDPIzmenaDenyThis(userAdminister) {
      await this.administerPDPIzmenaDeny(userAdminister._id);
      this.validation = `Uklonjen Pregled Procena za  ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerProcenaIzmenaThis(userAdminister) {
      await this.administerProcenaIzmena(userAdminister._id);
      this.validation = `Omogućen Izmena Procena za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerProcenaIzmenaDenyThis(userAdminister) {
      await this.administerProcenaIzmenaDeny(userAdminister._id);
      this.validation = `Uklonjen Izmena Procena za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerProcenaPregledThis(userAdminister) {
      await this.administerProcenaPregled(userAdminister._id);
      this.validation = `Omogućen Pregled Procena za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerProcenaPregledDenyThis(userAdminister) {
      await this.administerProcenaPregledDeny(userAdminister._id);
      this.validation = `Uklonjen Pregled Procena za  ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerCustomProcenaUserViewThis(userAdminister, name, active) {
      if (!active) {
        let data = {
          id: userAdminister._id,
          name: name,
        };
        await this.administerCustomProcenaUserView(data);
        this.validation = `Omogućen Pregled Procene ${name} za korisnika ${userAdminister.name} `;
        this.refreshListOfUsers();
      } else if (active) {
        let data = {
          id: userAdminister._id,
          name: name,
        };
        await this.administerCustomProcenaUserViewDeny(data);
        this.validation = `Onemogućen Pregled Procene ${name} za korisnika ${userAdminister.name} `;
        this.refreshListOfUsers();
      }
    },
    async administerKpiPregledThis(userAdminister) {
      await this.administerKpiPregled(userAdminister._id);
      this.validation = `Omogućen Pregled Kpi za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerKpiPregledDenyThis(userAdminister) {
      await this.administerKpiPregledDeny(userAdminister._id);
      this.validation = `Uklonjen Pregled Kpi za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerCustomQuestionnairePregledThis(userAdminister) {
      await this.administerCustomQuestionnairePregled(userAdminister._id);
      this.validation = `Omogućen Pregled Generisanog upitnika za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerCustomQuestionnairePregledDenyThis(userAdminister) {
      await this.administerCustomQuestionnairePregledDeny(userAdminister._id);
      this.validation = `Uklonjen Pregled Generisanog upitnika za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerFeedBackReportthis(userAdminister) {
      await this.administerFeedBackReport(userAdminister._id);
      this.validation = `Omogućen FeedBack 360 Izvestaj za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerFeedBackReportDenythis(userAdminister) {
      await this.administerFeedBackReportDeny(userAdminister._id);
      this.validation = `Uklonjen FeedBack 360 Izvestaj za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async resetKpithis(userAdminister) {
      await this.resetKpi(userAdminister._id);
      this.validation = `Restartovan kpi za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerVQ(userAdminister) {
      await this.administerVQTest(userAdminister._id);
      this.validation = `Omogućen VQ test za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerVQDeny(userAdminister) {
      await this.administerVQTestDeny(userAdminister._id);
      this.validation = `Uklonjen VQ test za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerTL(userAdminister) {
      await this.administerTLTest(userAdminister._id);
      this.validation = `Omogućen NEO PI-R test za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerTLDeny(userAdminister) {
      await this.administerTLTestDeny(userAdminister._id);
      this.validation = `Uklonjen NEO PI-R test za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerStavovi(userAdminister) {
      this.validation = `Omogućen upitnik za istraživanje stavova zaposlenih za ${userAdminister.name}`;
      await this.administerStavoviTest(userAdminister._id);
      this.refreshListOfUsers();
    },
    async administerStavoviDeny(userAdminister) {
      this.validation = `Uklonjen upitnik za istraživanje stavova zaposlenih za ${userAdminister.name}`;
      await this.administerStavoviTestDeny(userAdminister._id);
      this.refreshListOfUsers();
    },
    async administerFB(userAdminister) {
      await this.administerFBTest(userAdminister._id);
      this.validation = `Omogućen 360 FeedBack Upitnik za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerFBDeny(userAdminister) {
      await this.administerFBTestDeny(userAdminister._id);
      this.validation = `Uklonjen 360 FeedBack Upitnik za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerBelbin(userAdminister) {
      await this.administerBelbinTest(userAdminister._id);
      this.validation = `Omogućen belbin Upitnik za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    async administerBelbinDeny(userAdminister) {
      await this.administerBelbinTestDeny(userAdminister._id);
      this.validation = `Uklonjen belbin Upitnik za ${userAdminister.name}`;
      this.refreshListOfUsers();
    },
    // eslint-disable-next-line no-unused-vars

    async administerProcenaEdukacijeHere() {
      let data = {
        companyName: this.selected,
      };
      console.log("pre admin");
      await this.administerEducationEval(data);
      console.log("posle admin");
      let tempComp;
      for (var i = 0; i < this.companys.length; i++) {
        if (this.companys[i].name == this.selected) {
          tempComp = this.companys[i];
        }
      }
      await this.getCollegues(tempComp.name);

      for (i = 0; i < this.usersSameCompany.length; i++) {
        await this.administerProcenaEdukacije(this.usersSameCompany[i]._id);
      }

      this.validation = `DODATA NOVA PROCENA EDUKACIJE`;
      this.refreshListOfUsers();
    },

    async administerPerformanceEval(usersList, comp) {
      this.validation = "Obradjuje se zahtev...";
      if (confirm("Da li ste sigurni?")) {
        let companyKpiConnector = 0;
        for (var i = 0; i < this.companys.length; i++) {
          if (this.companys[i].name == comp) {
            companyKpiConnector =
              this.companys[i].performanceEvaluation.length - 1;
          }
        }
        for (i = 0; i < usersList.length; i++) {
          let kpiData = {
            userId: usersList[i]._id,
            companyKpiConnector: companyKpiConnector,
          };
          await this.addPerformanceEval(kpiData);
        }

        this.validation = `Dodat novi Performance Evaluation za selektovanu kompaniju. Treba uneti nove Licne KPI za svakog zaposlenog`;
      }
    },

    async administerPerformanceEvalLicni(usersList, comp) {
      this.validation = "Obradjuje se zahtev...";
      if (confirm("Da li ste sigurni?")) {
        let companyKpiConnector = 0;
        for (i = 0; i < this.companys.length; i++) {
          if (this.companys[i].name == comp) {
            companyKpiConnector =
              this.companys[i].performanceEvaluation.length - 1;
          }
        }
        for (var i = 0; i < usersList.length; i++) {
          let licniKpi = [];
          for (
            var j = 0;
            j <
            usersList[i].performanceEvaluation[
              usersList[i].performanceEvaluation.length - 1
            ]?.licniKpi?.length;
            j++
          ) {
            let licni = {
              name:
                usersList[i].performanceEvaluation[
                  usersList[i].performanceEvaluation.length - 1
                ]?.licniKpi[j]?.name,
              ponder:
                usersList[i].performanceEvaluation[
                  usersList[i].performanceEvaluation.length - 1
                ]?.licniKpi[j]?.ponder,
              comment:
                usersList[i].performanceEvaluation[
                  usersList[i].performanceEvaluation.length - 1
                ]?.licniKpi[j]?.comment,
            };
            licniKpi.push(licni);
          }
          let kpiData = {
            userId: usersList[i]._id,
            licniKpi,
            companyKpiConnector,
          };
          console.log("LICNI: " + usersList[i].name);
          console.log(kpiData.licniKpi);
          await this.addPerformanceEvalLicni(kpiData);
        }

        this.validation = `Dodat novi Performance Evaluation za selektovanu kompaniju. Treba uneti nove Licne KPI za svakog zaposlenog`;
      }
    },
    temporaryDataForProcenaModal() {
      //add temporary data for Procena modal
      this.listOfListsOfUsers[this.iMemory].forEach((ele) => {
        ele.availableTest.temporaryProcenaData = [];
        this.procenaFromServer.forEach((procena) => {
          if (!ele.availableTest.customProcenaView.includes(procena.name)) {
            ele.availableTest.temporaryProcenaData.push({
              name: procena.name,
              active: false,
            });
          } else {
            ele.availableTest.temporaryProcenaData.push({
              name: procena.name,
              active: true,
            });
          }
        });
      });
      console.log(this.usersSameCompany);
    },
    async refreshListOfUsers() {
      await this.getCollegues(this.selected);
      this.listOfListsOfUsers = [];
      this.companyList = [];
      this.companys.forEach((company) => {
        let list = [];
        if (company.name != "admin") {
          this.listOfListsOfUsers.push(list);
          this.companyList.push(company.name);
        }
      });

      for (var i = 0; i < this.usersSameCompany.length; i++) {
        this.listOfListsOfUsers[this.iMemory].push(this.usersSameCompany[i]);
      }
      this.temporaryDataForProcenaModal();
      // for (let i = 0; i < this.listOfListsOfUsers.length; i++) {
      //   let companyName = this.companyList[i];
      //   if(companyName!="admin"){
      //   for(let j = 0; j< this.users.length; j++){
      //     if(this.users[j].type === companyName){
      //       this.listOfListsOfUsers[i].push(this.users[j]);
      //     }
      //   }
      // }
      // }
    },
  },
  async created() {
    await this.getProfile();
    await this.getCompanies();

    this.companys.forEach((company) => {
      let list = [];

      if (company.name != "admin") {
        this.listOfListsOfUsers.push(list);
        this.companyList.push(company.name);
      }
    });
  },
};
</script>

<style lang="css" scoped>
.pointer {
  cursor: pointer;
}

.procena-modal {
  top: 40%;
  -webkit-box-shadow: -1px 1px 34px 17px rgba(0, 0, 0, 1);
  -moz-box-shadow: -1px 1px 34px 17px rgba(0, 0, 0, 1);
  box-shadow: -1px 1px 34px 17px rgba(0, 0, 0, 1);
}

.close-modal:hover {
  color: #fcfad3 !important;
  background-color: #dc3545 !important;
}

.container {
  display: flex;
  margin-top: 5px;
  justify-content: center;
}

.button-container {
  background-color: #fcfad3;
}

.bb {
  background-color: #ffaaaa;
}

.cc {
  background-color: #aaffaa;
}

.dd {
  background-color: #aaaaff;
}

.cc:hover {
  -webkit-box-shadow: inset 200px 200px 134px 193px rgba(255, 255, 255, 0.6);
  -moz-box-shadow: inset 200px 200px 134px 193px rgba(255, 255, 255, 0.6);
  box-shadow: inset 200px 200px 134px 193px rgba(255, 255, 255, 0.6);
}

.bb:hover {
  -webkit-box-shadow: inset 200px 200px 134px 193px rgba(255, 255, 255, 0.6);
  -moz-box-shadow: inset 200px 200px 134px 193px rgba(255, 255, 255, 0.6);
  box-shadow: inset 200px 200px 134px 193px rgba(255, 255, 255, 0.6);
}

.userName {
  width: 200px;
  border-color: #000410;
  border: 1px;
  text-decoration: none;
  border-style: solid;
}

.validation {
  margin-top: 5px;
  background-color: #00aaaa;
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  margin: auto;
  margin-top: 100px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
